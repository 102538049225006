@import '../../inputs/Dropdown/Dropdown.scss';
@import '../../../styles/media-queries.scss';
@import '../../../styles/mixins.scss';

$dropdown-bg-color: transparent;
$dropdown-color: white;

.system-selector .dropdown {
  @include dropdown($dropdown-bg-color, $dropdown-color);
  width: auto;
  display: inline-block;
  margin-top: 1rem;

  &:focus,
  &:focus-within {
    outline: auto;
  }

  &.open {
    .dropdown-toggle {
      .customCaret {
        color: #414042;
        border-color: #414042;
      }
    }
  }

  & button#system-selector {
    border-radius: 0;
    text-align: center;
    @include h1();
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background: transparent;
    margin: 0;
    color: #fff;
    z-index: 1001;

    .caret {
      display: inline-block;
      margin-top: -0.5rem;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 14px dashed;
      border-top: 4px solid \9;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      position: static;
    }

    &:hover {
      color: white;
      background: transparent;
    }
  }

  & .dropdown-menu {
    background-color: white;
    top: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    left: 0;
    right: 0;
    padding-right: 0;
    position: absolute;
    z-index: 1000;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    min-width: max-content;

    & li:last-child {
      a {
        overflow: visible;
      }
    }

    & li:not(:first-child) {
      border-top: 2px solid #bfbfbf;
    }

    & li {
      padding: 1.5rem 0;
      margin: 0 2rem;
      & a {
        color: #414042;
        padding-left: 0;
        @include h3();
        text-transform: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: all 0.2s ease-out;

        &:hover,
        &:focus {
          font-size: 1.5rem;
          font-weight: bold;
          & .button--round {
            width: 24px;
            height: 24px;
            left: 22px;
            & span {
              font-size: 1.4rem;
            }
          }
        }

        & .button--round {
          transition: all 0.2s ease-out;
          height: 20px;
          width: 20px;
          left: 18px;
          text-align: center;
          & span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-weight: 400;
            font-size: 1rem;
          }
        }

        & .add-system-label {
          margin-left: 25px;
          color: #000;
        }
      }
    }
  }

  &.open .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0 0 0 max(100vh, 100vw) rgba(0, 0, 0, 0.25);
  }
}

#system-selector {
  position: relative;
  padding: 0 0.5em !important;
}

.system-selector .dropdown:not(.open) button#system-selector:hover {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

@media screen and (max-width: $mobile) {
  .system-selector {
    .dropdown {
      & button#system-selector {
        font-size: 1.5em;
      }
    }
  }
}
