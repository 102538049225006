.menu-spinner-wrapper {
  height: 100%;
  width: 100%;
  .sk-child::before {
    background-color: #000;
  }
}

// to make time not overflow and get hidden
input[type='time'] {
  line-height: normal;
}

.devices .row .list-group {
  margin-top: 10px;
  border: none;
  padding: 1rem;
  margin-left: 2rem;

  li a {
    cursor: pointer;
  }

  li a:hover,
  li a:focus {
    text-decoration: none;
    font-weight: 400;
  }

  .inputComponent--has-error {
    margin-top: 10px;
  }

  .inputComponent__error {
    padding-bottom: 5px;
  }
}

.numberinputComponent {
  &__buttonicon {
    font-size: 3em;
  }

  &__element {
    padding-top: 6px;
  }
}

.menu-unavailable-wrapper {
  margin: 16px 0 16px 0;

  span {
    color: darkred;
    font-size: 1.5em;
  }
}

.list-group-item {
  border-top: 1px solid #ddd !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 10px !important;
  margin-bottom: 0 !important;

  .panel-group {
    margin-bottom: 0;
    padding: 0;
    .panel-default {
      border: none;
      box-shadow: none;
      .panel-heading {
        background: none;
        border: none;
        padding: 0;
        a {
          font-size: 0.875rem;
          padding: 0;
          background: transparent;
          display: inline-block;
          width: 100%;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .panel-collapse {
        .panel-body {
          border-top: 0;
        }
      }
    }
  }

  a {
    cursor: pointer;
    color: #414042;

    &:hover {
      color: #414042;
    }
  }

  &:last-child {
    border-bottom: 1px solid #ddd !important;
  }

  .numberinputComponent-container .text-replacer {
    min-width: 234px;
  }
  select {
    border: 2px solid #e2e2e2;
    padding: 10px;
  }
  .timePicker {
    justify-content: left;
  }
}

.bold-text {
  font-weight: bold;
  margin: 1rem;
}

.device-settings .list-item {
  background-color: #fff;
  padding: 0 2rem;
}

.menu-heading {
  display: flex;
  justify-content: space-between;
}

#replacer.popover > .arrow:after {
  border-left-color: transparent !important;
  border-top-color: #5d5d5d;
}

.slider-container {
  margin-top: 1rem;
  .input-range__label-container {
    color: #414042;
    left: 0;
  }
}
.slider-item {
  height: 4.5rem;
}

.device-menu-item-header {
  display: flex;
  align-items: center;
  gap: 20px;

  .sk-circle {
    margin: 0;
    height: 15px;
    width: 15px;
  }
}

.device-menu-row {
  .inputComponent__placeholder__label {
    margin: 0;
  }
}
