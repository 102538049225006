@import '../../../styles/variables.scss';
@import '../../../styles/media-queries.scss';

.switch {
  position: relative;
  display: inline-block;
  min-width: 4rem;
  max-width: 4rem;
  height: 2.5rem;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c4c4c4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 4rem;
  }

  & input:disabled + .slider {
    cursor: not-allowed;
  }

  & .slider:before {
    position: absolute;
    content: '';
    height: 2rem;
    width: 2rem;
    left: 0.25rem;
    bottom: 0.25rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  & input:checked + .slider {
    background-color: $toggle-switch-color;
  }

  & input:checked + .slider:before {
    -webkit-transform: translateX(1.5rem);
    -ms-transform: translateX(1.5rem);
    transform: translateX(1.5rem);
  }

  &--big {
    min-width: 6rem;
    max-width: 6rem;
    height: 3.5rem;
    margin: 1rem 0;

    & .slider {
      border-radius: 6rem;
    }

    & .slider:before {
      height: 3rem;
      width: 3rem;
    }

    & input:checked + .slider:before {
      -webkit-transform: translateX(2.5rem);
      -ms-transform: translateX(2.5rem);
      transform: translateX(2.5rem);
    }
  }

  @media all and (max-width: $desktop) and (min-width: $tablet) {
    margin: 1vw 0;
    min-width: 5.5vw;
    max-width: 5.5vw;
    height: 3vw;

    & .slider {
      border-radius: 8vw;
    }

    & .slider:before {
      height: 2.5vw;
      width: 2.5vw;
      left: 0.25vw;
      bottom: 0.25vw;
      border-radius: 50%;
    }

    & input:checked + .slider:before {
      -webkit-transform: translateX(2.5vw);
      -ms-transform: translateX(2.5vw);
      transform: translateX(2.5vw);
    }

    &--big {
      margin: 0.75vw 0;
      min-width: 8vw;
      max-width: 8vw;
      height: 4.5vw;

      & .slider {
        border-radius: 8vw;
      }

      & .slider:before {
        height: 3.9vw;
        width: 3.9vw;
        left: 0.3vw;
        bottom: 0.3vw;
        border-radius: 50%;
      }

      & input:checked + .slider:before {
        -webkit-transform: translateX(3.4vw);
        -ms-transform: translateX(3.4vw);
        transform: translateX(3.4vw);
      }
    }
  }
}

.toggle-text {
  font-size: 20px;
  line-height: 2.5;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 1rem;
  color: $font-color;
}

.toggle-switch-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .premium-badge-tooltip {
    bottom: 100px;
    margin: auto;
  }
}
