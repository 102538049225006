@import '../../../styles/media-queries.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.device-scheduling__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.timeslots {
  width: 100%;
  margin-bottom: 2.5em;
  background-color: #c4c4c4;
  height: 4em;
  border-radius: 0.5em;

  display: flex;
  flex-direction: row;
  vertical-align: middle;

  .timeslot {
    height: 100%;
    padding: 0 1em;
    text-align: center;

    &:not(.timeslot-eod) {
      border-right: solid 0.3em white;
    }

    &:first-child {
      border-radius: 0.5em 0 0 0.5em;
    }

    &.timeslot-eod {
      border-radius: 0 0.5em 0.5em 0;

      &:first-child {
        border-radius: 0.5em;
      }
    }

    &.hovered {
      opacity: 0.5;
      transition: 0.5s;
    }

    &.timeslot-disabled {
      background: repeating-linear-gradient(315deg, transparent, transparent 10px, #000 12px, #000 12px);
      opacity: 0.5;
    }

    .timeslot-name {
      margin-top: 4em;
    }
  }
}

.rdrDateDisplayWrapper {
  display: none !important;
}

.dateRangeWrapper {
  border: 1px solid #849095;
  border-radius: 0.5em;
  padding: 1em;

  .rdrCalendarWrapper {
    width: 100%;
  }

  .rdrMonth {
    width: 100%;
  }
}

.vacation-schedule {
  margin: auto;
  width: 480px;
}

.week-format {
  position: relative;
  padding-top: 2em;

  .button[disabled]:hover {
    cursor: not-allowed;

    & + .tooltip {
      left: 2.5%;
    }
  }
}

.error-message {
  color: #a83131;
  font-size: 20px;
}

.mode-popup {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 25px;
  border-radius: 6px;
  background-color: #dfdfdf;
  text-align: center;

  .button--cancel {
    border-radius: 50%;
    padding: 8px;
    min-width: 35px;
    height: 35px;
    cursor: pointer;
    background: $welcomePageBackground;
    box-shadow: 0 0 15px #0000005c;
    position: absolute;
    top: -18px;
    right: -18px;
    i {
      color: black;
    }
  }

  .text {
    margin-top: 20px;
    text-align: center;
    line-height: 1.5em;
    font-size: 1.2em;

    &--small {
      font-size: 0.8em;
    }
  }

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 17px;
  }

  .titleComponent {
    margin: 0;
    font-size: 1.8em;
  }

  .popup-header {
    position: relative;
    .vacationDate {
      margin-bottom: 0;
    }
    .vacationTime {
      margin-top: 0;
    }
  }

  .popup-main {
    text-align: center;
    margin-bottom: 60px;

    .button--info.active {
      background-color: #828282;
      color: #fff;

      &:hover {
        color: #fff !important;
      }
    }
  }

  .popup-item {
    display: flex;
    justify-content: space-between;

    .info-button {
      width: 54px;
      border-radius: 6px;
      margin: 0.5rem 0 0.5rem 0.5rem;
      background: #fff;
      text-align: center;
    }

    .icon-wrapper.grey .info-button {
      background: #eee;
      cursor: not-allowed;
    }

    .icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 26px;
      border-radius: 50%;
      text-align: center;
      margin: 11px;
      border: 2px solid #414042;

      &-wrapper {
        height: 68px;
        display: flex;
      }
    }

    &.active {
      .button--info,
      .info-button {
        background-color: #828282;
        color: #fff;

        &:hover {
          color: #fff !important;
        }
      }

      .icon {
        border-color: #fff;
      }
    }

    .active-mode {
      color: #4bb945;
    }
  }

  .popup-body {
    width: 100%;

    .button--info {
      border-radius: 6px;
      padding: 1rem 1.5rem;
      font-size: 1.2rem;
      transition: 0.3s;

      &:hover {
        color: #000 !important;
        font-size: 1.4rem;
      }
    }

    .button--info:disabled:hover {
      font-size: 1.2rem;
    }

    .text.center {
      text-align: center;
    }

    .edit-mode-item {
      display: flex;

      .checkbox-wrapper {
        padding-top: 0;
        margin-top: 4px;
      }

      .checkboxComponent label {
        margin: 0;
        width: 66px;
        height: 60px;

        &:before {
          border: none;
          width: 50px;
          height: 51px;
          margin: 0px 8px;
          border-radius: 6px;
        }
      }

      .checkboxComponent input:checked + label:after {
        left: 28px;
        height: 26px;
        top: 12px;
        border: solid #414042;
        border-width: 0px 6px 6px 0;
      }

      .numberinputComponent__input {
        width: 100px;
        height: 62px;
        background: transparent;
        text-overflow: ellipsis;
        min-width: 170px;
        max-width: 170px;
      }

      .numberinputComponent__button {
        width: 62px;
        height: 62px;
        border-radius: 6px;
      }
    }
  }

  .popup-bottom-panel {
    display: block;
    width: 43%;
    margin: 40px auto 0;
    position: relative;

    .tooltip {
      opacity: 0;
      display: none;
    }

    .button--default.delete-event[disabled]:hover + .tooltip {
      display: block;
      opacity: 0.5;
      top: 11em;
      left: 19.3em;
      width: 135px;

      &:after {
        top: 37%;
        left: -4px;
        transform: rotate(90deg);
      }
    }

    .button--default {
      margin-top: 20px;
    }

    .button {
      width: 100%;
      margin-left: 0;
    }

    .edit-modes-delete[disabled]:hover + .tooltip {
      display: block;
      bottom: 11.5em;
      top: auto;
      left: 23em;
    }

    .edit-modes-rename[disabled]:hover + .tooltip {
      display: block;
      bottom: 17.5em;
      top: auto;
      left: 23em;
    }
  }

  .popup-header .edit-modes-add[disabled]:hover + .tooltip {
    display: block;
    top: auto;
    bottom: 3.5em;
    left: -6.5em;
    opacity: 1;
  }

  .popup-main .edit-mode-item .checkbox-wrapper:hover + .tooltip {
    display: block;
    opacity: 1;
    top: auto;
    left: auto;
  }

  .toggle-switch-wrapper,
  .numberinputComponent__container,
  .dropdown {
    width: 198px;
    height: 62px;
    margin: 0.5rem 0 0.5rem 0.5rem;
  }

  .toggle-switch-wrapper {
    justify-content: center;
    transform: scale(1.25);
  }

  .rdrStartEdge,
  .rdrEndEdge,
  .rdrInRange,
  .rdrDayInPreview,
  .rdrDayToday .rdrDayNumber span:after {
    background: #1a237e;
    opacity: 0.5;
  }
}

.rdrDay:not(.rdrDayPassive) {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber {
      span {
        color: #414042;
      }
    }
  }
}

.rdrDayNumber {
  z-index: 2;

  span {
    color: #414042;
  }
}

.rdrDayToday:not(.rdrDayPassive) {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber span:after {
      display: none;
    }
  }
}

.rdrStartEdge,
.rdrEndEdge,
.rdrInRange,
.rdrDayInPreview,
.rdrDayToday .rdrDayNumber span:after {
  border: 0;
  opacity: 0.5;
  cursor: pointer;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayHovered,
.rdrDayStartPreview:active {
  border: 0 !important;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  border: 0 !important;
}

.edit-modes-btn-container {
  position: relative;

  &:first-of-type {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    &:hover .tooltip {
      display: block;
      left: unset;
      right: -1em;
    }
  }

  &:hover .tooltip {
    display: block;
    opacity: 0.5;
    top: -2.5em;
    left: -1em;
  }

  .button--default {
    color: #414042;
    background: #e2e2e2;
    &:hover {
      color: #e2e2e2;
      background: #414042;
    }
  }
}

.schedule-override {
  max-width: 20em;
  width: 100%;
  padding: 1.2em 1.5em;
  max-height: 8.5em;
  background: linear-gradient(#004168, #1a227e);
  border-radius: 0.7em;

  label {
    font-weight: normal;
    font-size: 1.3rem;
  }

  .switch {
    margin: 0;
  }

  .sk-circle {
    margin: 30px auto;
  }

  .control-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & label {
      color: #fff;
      line-height: 2em;
      margin-bottom: 0;
    }

    &.opacity label,
    &.opacity button {
      opacity: 0.6;
      width: unset;
      min-width: unset;
      padding-bottom: 7px;
    }
  }

  .toggle-switch-wrapper {
    position: relative;
    gap: 1em;

    .toggle-text {
      margin: 0;
      color: #fff;
      line-height: 1;
    }

    .switch[disabled]:hover + .tooltip {
      display: block;
      bottom: 56px;
      left: -92%;
    }
  }

  .dropdown {
    position: relative;
    width: unset;
    button,
    .open > .btn-default.dropdown-toggle:hover,
    .open > .btn-default.dropdown-toggle:focus {
      margin-top: unset !important;
      background-color: transparent !important;
      color: #fff !important;
      border: none !important;
    }
  }

  .btn-group.open .dropdown-toggle {
    box-shadow: none !important;
    background: transparent !important;
    opacity: 0.6;
  }

  .dropdown {
    &.open .caret {
      &:before {
        transform: rotate(225deg);
        top: 10px;
      }
    }

    &.disabled:hover {
      cursor: not-allowed;

      & + .tooltip {
        display: block;
        opacity: 1;
        top: 106px;
        left: 21%;
      }
    }

    &.disabled .caret {
      display: block;
    }

    .dropdown-menu {
      top: 101%;
      opacity: 1;

      li:first-child {
        border-top: none;
      }
    }

    & button:hover {
      background-color: transparent !important;
    }

    .dropdown-menu {
      min-width: 137px;

      li {
        padding: 0.5rem 0;
        margin: 0px 0.6rem;
      }
    }
  }

  .dropdown-toggle {
    .caret {
      position: relative;
      top: 0;
      bottom: 0;
      right: -1px;
      width: 50px;
      height: 40px;
      border-top: 0;
      border-radius: 6px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 16px;
        width: 20px;
        height: 20px;
        border: solid #fff;
        border-width: 0px 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    .textWrapper {
      font-size: 1.2em;
    }
  }

  .btn:active,
  .btn.active {
    box-shadow: none;
  }
}

#timeslot .arrow:after {
  border-top-color: #5d5d5d !important;
  border-left-color: transparent !important;
}

.day-selector-item {
  width: 100%;
  background-color: #fff;
  padding: 0.7em 0;
  margin-bottom: 1em;
  border-radius: 0.25em;
  cursor: pointer;

  &--selected {
    background-color: #828282;
    color: #fff;
  }

  &:hover {
    opacity: 0.7;
  }
}

#replacer .popover-content {
  text-align: left !important;
  font-size: 1rem;
  word-wrap: break-word;
}

.popover {
  background-color: unset !important;
}

.popover.right > .arrow:after {
  border-right-color: #5d5d5d !important;
}

.control-panel .numberinputComponent-container .text-replacer {
  min-width: 150px;
  max-width: 150px;
}
