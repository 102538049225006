@import '../../../styles/mixins.scss';

.product-registration-info {
  @include contentLayoutWrapper();

  .sk-circle .sk-child:before {
    background-color: black !important;
  }

  &__wrapper {
    margin-top: 2em;
  }
}
