@import '../../../styles/variables.scss';

.slider-container {
  .input-range__label {
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #414042;
    font-size: 1rem;

    &--value {
      top: -2rem;
    }
  }

  .input-range__track--active {
    background-color: #fe7700;
  }

  .input-range__slider {
    background-color: #fe7700;
    border: none;
  }

  .input-range__track {
    pointer-events: none;
  }

  .input-range__slider-container {
    pointer-events: all;
  }

  .input-range--disabled:hover + .tooltip {
    opacity: 1;
  }

  .input-range--disabled:hover + .premium-badge-tooltip {
    bottom: 70px;
    right: unset;
    left: calc((100% - #{$tooltip-width}) / 2);
    top: auto;
  }
}
